import React from 'react'
import '../../../components/ParagraphUnderPhoto/styles.css'
import './styles.css'
import styled from 'styled-components'
import Carousel from 'react-multi-carousel'
import { UnderlineYellow } from '../../../components/Typography'

const Articles = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }

  const Square = styled.div`
    cursor: pointer;
    transition: all 250ms ease-in;
    background-color: ${({ active }) => (active ? '#d5d110' : 'transparent')};
    color: ${({ active }) => (active ? '#fff' : '333')};
    transform: scale(${({ active }) => (!active ? 1.1 : 1)});
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 10px;
    border: 2px solid #d5d110 !important;
    height: 10px !important;
    width: 55px !important;
    border-radius: 11px !important;
    box-shadow: none !important;
  `
  const Flex = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => direction};
  `

  const Button = styled.button`
    background: none;
    border: none;
    font-size: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    &:disabled {
      cursor: not-allowed;
    }
  `
  /*
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? '<' : '>'
    return (
      <Button onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    )
  }
*/
  return (
    <>
      <div className="container WriteAboutUs--Wrapper">
        <h2 className="WriteAboutUs--Wrapper">Napsali o nás</h2>
        <UnderlineYellow />
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={7000}
          centerMode={false}
          className="WriteAboutUs-Wrap-Carousel"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          <div className="CardWrap">
            <a
              className="WriteAboutUS--Wrapper-logo"
              href="https://archiv.hn.cz/c1-67048470-sporeni-na-stari-se-muze-zkomplikovat-napad-zvysit-fondum-dane-je-podle-expertu-nelogicky"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo/hospodarske_noviny_logo.png"
                className="reference-logo"
                alt="ihned.cz"
              ></img>
              <h2>
                Spoření na stáří se může zkomplikovat. Nápad zvýšit fondům daně
                je podle expertů nelogický
              </h2>
            </a>
            <p>
              Republiku poslední měsíce válcuje inflace a i některé
              konzervativnější Čechy už dotlačila k investování. Kdo si ale
              vybral ke zhodnocení peněz investiční fondy, mohl by být brzy
              nemile překvapen. Ministerstvo...
            </p>
            <div className="ButtonWrap">
              <a
                href="https://archiv.hn.cz/c1-67048470-sporeni-na-stari-se-muze-zkomplikovat-napad-zvysit-fondum-dane-je-podle-expertu-nelogicky"
                target="_blank"
                rel="noopener noreferrer"
              >
                Více
              </a>
            </div>
          </div>
          <div className="CardWrap">
            <a
              className="WriteAboutUS--Wrapper-logo"
              href="https://investice.hn.cz/c1-67024160-vydelali-jste-na-burze-nezapomente-na-dane-jak-na-ne-se-ptejte-experta-eisenworta"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo/hospodarske_noviny_logo.png"
                className="reference-logo"
                alt="ihned.cz"
              ></img>
              <h2>
                Vydělali jste na burze? Nezapomeňte na daně. Jak na ně,
                odpovídali experti z EKP Advisory
              </h2>
            </a>
            <p>
              Hodně lidí loni na burzách solidně vydělalo, pokud své zisky
              realizovali, musí ale také myslet na berní úřad. Proto přinášíme
              odpovědi na otázky čtenářů ohledně danění investic od daňových
              expertů z EKP Advisory...
            </p>
            <div className="ButtonWrap">
              <a
                href="https://investice.hn.cz/c1-67024160-vydelali-jste-na-burze-nezapomente-na-dane-jak-na-ne-se-ptejte-experta-eisenworta"
                target="_blank"
                rel="noopener noreferrer"
              >
                Více
              </a>
            </div>
          </div>
          <div className="CardWrap">
            <a
              className="WriteAboutUS--Wrapper-logo"
              href="https://www.penize.cz/burza-cennych-papiru-praha/427377-ppf-chce-stahnout-akcie-o2-z-burzy-chysta-vytesneni"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo/penize_logo.png"
                className="reference-logo"
                alt="Peníze.cz"
              ></img>
              <h2>PPF chce stáhnout akcie O2 z burzy, chystá vytěsnění</h2>
            </a>
            <p>
              Skupina PPF chce kompletně ovládnout operátora O2 Czech Republic.
              Přes společnost PPF Telco koupila od kvalifikovaných investorů
              další akcie, s nimiž se obchodovalo na pražské burze. Záměr dostat
              se metodou takzvaného zrychleného odkupu přes 90% podíl oznámila
              ve...
            </p>
            <div className="ButtonWrap">
              <a
                href="https://www.penize.cz/burza-cennych-papiru-praha/427377-ppf-chce-stahnout-akcie-o2-z-burzy-chysta-vytesneni"
                target="_blank"
                rel="noopener noreferrer"
              >
                Více
              </a>
            </div>
          </div>
          <div className="CardWrap">
            <a
              className="WriteAboutUS--Wrapper-logo"
              href="https://www.penize.cz/kryptomeny/423676-jak-zdanit-zisk-z-prodeje-bitcoinu-casovy-test-nepomuze"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo/penize_logo.png"
                className="reference-logo"
                alt="Peníze.cz"
              ></img>
              <h2>
                Vydělali jste na bitcoinu? Před daní vás nezachrání ani čas
              </h2>
            </a>
            <p>
              Zapomeňte na časový test. Zisk z prodeje bitcoinu a dalších
              kryptoměn se daní, přestože jste je drželi déle než tři roky. Jak
              na to? A „vidí“ finanční správa na burzy virtuálních měn? Růst
              bitcoinu a některých dalších virtuálních měn na nové rekordní
              úrovně zvýší i počet lidí, kteří...
            </p>
            <div className="ButtonWrap">
              <a
                href="https://www.penize.cz/kryptomeny/423676-jak-zdanit-zisk-z-prodeje-bitcoinu-casovy-test-nepomuze"
                target="_blank"
                rel="noopener noreferrer"
              >
                Více
              </a>
            </div>
          </div>
          <div className="CardWrap">
            <a
              className="WriteAboutUS--Wrapper-logo"
              href="https://byznys.ihned.cz/c1-66765800-vydelal-jsem-na-revolutu-a-jde-po-mne-bernak-jak-takove-situaci-predejit-ptejte-se-danoveho-experta"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo/hospodarske_noviny_logo.png"
                className="reference-logo"
                alt="ihned.cz"
              ></img>
              <h2>
                Vydělal jsem na Revolutu a jde po mně berňák, co dělat?
                Odpovídal daňový expert Eisenwort z EKP Advisory
              </h2>
            </a>
            <p>
              V posledních měsících mezi Čechy prudce vzrostl zájem o
              investování, kdy se sami učí z tutoriálů na YouTube či webinářů
              poskytovaných řadou firem zdarma. K investování používají burzovní
              platformy, portály bank...
            </p>
            <div className="ButtonWrap">
              <a
                href="https://byznys.ihned.cz/c1-66765800-vydelal-jsem-na-revolutu-a-jde-po-mne-bernak-jak-takove-situaci-predejit-ptejte-se-danoveho-experta"
                target="_blank"
                rel="noopener noreferrer"
              >
                Více
              </a>
            </div>
          </div>
          <div className="CardWrap">
            <a
              className="WriteAboutUS--Wrapper-logo"
              href="https://www.youtube.com/watch?v=9tUor_g-Kr4&feature=emb_title"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo/paralelni_polis_logo.png"
                className="reference-logo"
                alt="paralelnipolis.cz"
              ></img>
              <h2>
                YouTube: Bitcoin meetup | Virtuální měny v daních (20. 3. 2018)
              </h2>
            </a>
            <p>
              V úterý 20. března 2O2O jsme v prostorách Paralelní Polis
              uspořádali přednášku s názvem Virtuální měny v daních. Hlavním
              přednášejícím byl jeden z našich partnerů Jan Kotala, střídali ho
              kolega Michal Friedrich a JUDr. Josef Žaloudek
            </p>
            <div className="ButtonWrap">
              <a
                href="https://www.youtube.com/watch?v=9tUor_g-Kr4&feature=emb_title"
                target="_blank"
                rel="noopener noreferrer"
              >
                Více
              </a>
            </div>
          </div>
          <div className="CardWrap">
            <a
              className="WriteAboutUS--Wrapper-logo"
              href="https://archiv.ihned.cz/c1-66021960-evropska-komise-predstavila-cast-reformy-dph-sazby-si-maji-vybirat-samy-staty"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo/hospodarske_noviny_logo.png"
                className="reference-logo"
                alt="ihned.cz"
              ></img>
              <h2>
                Evropská komise představila část reformy DPH. Svobodu v určování
                výše sazeb získá každý unijní stát
              </h2>
            </a>
            <p>
              Evropská komise představila první část návrhů, jak reformovat
              nejvýznamnější daň v Evropě - DPH U stanovení sazeb DPH navrhuje
              obrátit současnou praxi - stanoví několik věcí, jako například
              alkohol nebo...
            </p>
            <div className="ButtonWrap">
              <a
                href="https://archiv.ihned.cz/c1-66021960-evropska-komise-predstavila-cast-reformy-dph-sazby-si-maji-vybirat-samy-staty"
                target="_blank"
                rel="noopener noreferrer"
              >
                Více
              </a>
            </div>
          </div>
          <div className="CardWrap">
            <a
              className="WriteAboutUS--Wrapper-logo"
              href="https://zahranicni.ihned.cz/c1-65951720-vyuzivani-danovych-raju-je-v-cesku-na-ustupu-dokud-eu-nesladi-dane-cesta-vyvadet-penize-tu-bude-vzdy-rika-danovy-poradce"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo/hospodarske_noviny_logo.png"
                className="reference-logo"
                alt="ihned.cz"
              ></img>
              <h2>
                Využívání daňových rájů je v Česku na ústupu. Dokud EU nesladí
                daně, cesta vyvádět peníze tu bude vždy, říká daňový poradce
              </h2>
            </a>
            <p>
              Odlišné daňové systémy v Evropské unii vytvořily cestičky pro
              vyvádění peněz fyzicky ven. "Dokud v EU nedosáhneme sladění
              přímých daní, což je cesta na roky, možná desetiletí...
            </p>
            <div className="ButtonWrap">
              <a
                href="https://zahranicni.ihned.cz/c1-65951720-vyuzivani-danovych-raju-je-v-cesku-na-ustupu-dokud-eu-nesladi-dane-cesta-vyvadet-penize-tu-bude-vzdy-rika-danovy-poradce"
                target="_blank"
                rel="noopener noreferrer"
              >
                Více
              </a>
            </div>
          </div>
          <div className="CardWrap">
            <a
              className="WriteAboutUS--Wrapper-logo"
              href="/files/Bulletin2_2017.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo/komora_danovych_poradcu_logo.png"
                className="reference-logo"
                alt="ihned.cz"
              ></img>
              <h2>
                K důkaznímu břemenu u karuselových podvodů aneb Věděl, nebo
                vědět měl a mohl
              </h2>
            </a>
            <p>
              Karuselové podvody jsou v současnosti jedním z nejhorlivěji
              diskutovaných témat ve vztahu k dani z přidané hodpoty a jako
              takové se těší mimo jiné i značné mediální pozornosti. Finanční
              správa v současnosti disponuje množstvím efektivních nástrojů...
            </p>
            <div className="ButtonWrap">
              <a
                href="/files/Bulletin2_2017.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Více
              </a>
            </div>
          </div>
          <div className="CardWrap">
            <a
              className="WriteAboutUS--Wrapper-logo"
              href="https://archiv.ihned.cz/c1-65581670-babisuv-byznys-v-hodnote-desitek-miliard-zastresi-jediny-fond-bude-nejvetsi-v-cesku"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo/hospodarske_noviny_logo.png"
                className="reference-logo"
                alt="ihned.cz"
              ></img>
              <h2>
                Babišův byznys v úschově: Zastřeší ho jediný fond, bude největší
                v Česku. Vliv na 250 firem si ale ministr financí zachová
              </h2>
            </a>
            <p>
              V případě schválení tzv. Lex Babiš, zahájí ministr financí
              největší přesun soukromého majetku v Česku. by vyhověl zákonu a
              jeho firmy mohly přitom dál pobírat dotace a ucházet se o
              veřejné...
            </p>
            <div className="ButtonWrap">
              <a
                href="https://archiv.ihned.cz/c1-65581670-babisuv-byznys-v-hodnote-desitek-miliard-zastresi-jediny-fond-bude-nejvetsi-v-cesku"
                target="_blank"
                rel="noopener noreferrer"
              >
                Více
              </a>
            </div>
          </div>
          <div className="CardWrap">
            <a
              className="WriteAboutUS--Wrapper-logo"
              href="https://zpravy.aktualne.cz/ekonomika/babisovo-snizeni-dane-se-na-cene-piva-neprojevi/r~6355e1b4c68511e59e52002590604f2e/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo/aktualne_logo.png"
                className="reference-logo"
                alt="aktuálně.cz"
              ></img>
              <h2>
                Babišovo snížení daně se na ceně piva neprojeví, říkají
                odborníci. Uškodí malým hospodám
              </h2>
            </a>
            <p>
              Babišovo snížení daně se na ceně piva neprojeví, říkají odborníci.
              Uškodí malým hospodám? Lukáš Eisenwort reaguje na Babišův návrh
              snížení daní: „Babiš, nebude to mít na jeho cenu žádný vliv, tvrdí
              odborníci. „Ze zkušenosti...
            </p>
            <div className="ButtonWrap">
              <a
                href="https://zpravy.aktualne.cz/ekonomika/babisovo-snizeni-dane-se-na-cene-piva-neprojevi/r~6355e1b4c68511e59e52002590604f2e/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Více
              </a>
            </div>
          </div>
          <div className="CardWrap">
            <a
              className="WriteAboutUS--Wrapper-logo"
              href="https://byznys.ihned.cz/c1-65057160-cast-akcionaru-vytesnenych-z-firmy-cetin-bude-resit-dane-ale-budou-vubec-v-zisku"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logo/hospodarske_noviny_logo.png"
                className="reference-logo"
                alt="ihned.cz"
              ></img>
              <h2>
                Část akcionářů vytěsněných z firmy Cetin bude řešit daně. Ale
                budou vůbec v zisku?
              </h2>
            </a>
            <p>
              Asi 30 tisíc akcionářů České telekomunikační infrastruktury
              (Cetin) přijde 11. ledna o podíl v této společnosti. Jejich akcie
              převezme firma PPF.A4B.V., jejíž podíl v Cetinu dosáhl 90 procent.
              Využila tak svého práva na vytěsnění ostatních akcionářů...
            </p>
            <div className="ButtonWrap">
              <a
                href="https://byznys.ihned.cz/c1-65057160-cast-akcionaru-vytesnenych-z-firmy-cetin-bude-resit-dane-ale-budou-vubec-v-zisku"
                target="_blank"
                rel="noopener noreferrer"
              >
                Více
              </a>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  )
}
export default Articles
