import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import './styles.css'
import dane from './../../../../static/images/dane.png'
import firemniporadenstvi from './../../../../static/images/firemniPoradenstvi.png'
import mzdy from './../../../../static/images/mzdy.png'
import ucetnictvi from './../../../../static/images/ucetnictvi.png'
import Img from 'gatsby-image'
import { Link } from '@reach/router'
import Carousel from 'react-multi-carousel'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
}

const HomepageLanding = ({ photo1, photo2, ekpLogo }) => (
  <div className="LandingPhoto">
    <div className="row align-items-center">
      <div
        className="HomepagePhoto--Wrap col-xl-6 col-lg-12"
        style={{ backgroundColor: '#e2e1dd', maxWidth: 'unset' }}
      >
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          slidesToSlide={false}
          autoPlay={true}
          autoPlaySpeed={5000}
          centerMode={false}
          className="HomepagePhoto--Wrap"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={true}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          <div>
            <Img fluid={photo1.fluid} alt="Homepage Lukas Eisenwort" />
            <Link
              to={'/o-nas/nas-tym/lukas-eisenwort'}
              className="homepage-picture-text"
            >
              <div className="homepage--name">Lukáš Eisenwort</div>
              <div className="homepage--position">partner, daňový poradce</div>
            </Link>
          </div>
          <div>
            <Img fluid={photo2.fluid} alt="Homepage Jan Kotala" />
            <Link to={'/o-nas/nas-tym/jan-kotala'}>
              <div className="homepage--name">Jan Kotala</div>
              <div className="homepage--position">partner, daňový poradce</div>
            </Link>
          </div>
        </Carousel>
      </div>
      <div className="col-xl-5 offset-xl-1 col-lg-12">
        <div className="container row justify-content-center">
          <div className="h1-mobile col-12">
            <h1 style={{ display: 'none' }}>EKP Advisory, s.r.o.</h1>
            <h2 style={{ display: 'none' }}>daňoví a účetní poradci</h2>
            <Img
              className="homepage-logo-padding"
              fixed={ekpLogo.fixed}
              alt="Homepage photo"
            />
          </div>
          <div className="col-xl-6 col-lg-3 col-sm-6 col-12 wrapper">
            <Link to="/sluzby/dane/">
              <div className="LandingPhoto--Wrap LandingPhoto--WrapXL--Right">
                <img src={dane} alt="Daně" />
                <h2>Daně</h2>
              </div>
            </Link>
          </div>
          <div className="col-xl-6 col-lg-3 col-sm-6 col-12 wrapper">
            <Link to="/sluzby/ucetnictvi/">
              <div className="LandingPhoto--Wrap LandingPhoto--WrapXL--Left">
                <img src={ucetnictvi} alt="Účetnictví" />
                <h2>Účetnictví</h2>
              </div>
            </Link>
          </div>
          <div className="col-xl-6 col-lg-3 col-sm-6 col-12 wrapper">
            <Link to="/sluzby/firemni-poradenstvi/">
              <div className="LandingPhoto--Wrap LandingPhoto--WrapXL--Right">
                <img src={firemniporadenstvi} alt="Firemní poradenství" />
                <h2>Firemní poradenství</h2>
              </div>
            </Link>
          </div>
          <div className="col-xl-6 col-lg-3 col-sm-6 col-12 wrapper">
            <Link to="/sluzby/mzdy/">
              <div className="LandingPhoto--Wrap LandingPhoto--WrapXL--Left">
                <img src={mzdy} alt="Mzdy" />
                <h2>Mzdy</h2>
              </div>
            </Link>
          </div>
          <div className="h1-desktop col-12">
            <h2 className="h2-mobile" style={{ display: 'none' }}>
              EKP Advisory, s.r.o.
            </h2>
            <h2 style={{ display: 'none' }}>daňoví a účetní poradci</h2>
            <Img
              className="homepage-logo-padding"
              fixed={ekpLogo.fixed}
              alt="Homepage photo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const HomepageLandingData = () => {
  const { homepageEisenwort, homepageKotala, ekpLogo } = useStaticQuery(graphql`
    query {
      homepageEisenwort: file(
        relativePath: { eq: "homepage_lukas_eisenwort.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      homepageKotala: file(relativePath: { eq: "homepage_jan_kotala.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ekpLogo: file(relativePath: { eq: "homepage/ekp_logo_homepage_CZ.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <HomepageLanding
      photo1={homepageEisenwort.childImageSharp}
      photo2={homepageKotala.childImageSharp}
      ekpLogo={ekpLogo.childImageSharp}
    />
  )
}

export default HomepageLandingData
