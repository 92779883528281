import styled from 'styled-components'
import theme from '../../../common/theme'

export const Background = styled.div`
  background-image: url('/images/homepage/newsletter.jpeg');
  padding: 130px 50px;
`
export const Button = styled.a`
  background-color: ${theme.color.blue};
  color: ${theme.color.white};
  display: block;
  font-size: 30px;
  padding: 25px 20px;
  border-radius: 11px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  transition-duration: 0.3s;
  border: 4px solid transparent;

  @media (max-width: 1199px) {
    font-size: 20px;
  }

  &:hover {
    background-color: ${theme.color.yellow};
    color: ${theme.color.blue};
    transition-duration: 0.3s;
    border: 4px solid ${theme.color.blue};
  }
`

export const Title = styled.h3`
  font-size: 40px;
  font-weight: bold;
  line-height: 40px;
  @media (max-width: 992px) {
    margin-bottom: 32px;
  }
  @media (max-width: 767px) {
    text-align: center;
    margin-bottom: 32px;
  }
`
