import React from 'react'
import * as S from './styled'
import { Link } from '@reach/router'
const Newsletter = () => {
  return (
    <S.Background>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8">
            <S.Title>
              Získejte pravidelné informace ze světa účetnictví a daní
            </S.Title>
          </div>
          <div className="col-md-4">
            <Link to={'/newsletter/'}>
              <S.Button>Odebírat newsletter</S.Button>
            </Link>
          </div>
        </div>
      </div>
    </S.Background>
  )
}

export default Newsletter
