import React from 'react'
import * as S from './styled'
import '../../../components/ParagraphUnderPhoto/styles.css'
import './../OurTeam/styles.css'
import Carousel from 'react-multi-carousel'
const References = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }
  return (
    <S.Wrapper className="container">
      <S.H2Blue>Reference</S.H2Blue>
      <S.UnderlineYellow />

      <Carousel
        responsive={responsive}
        additionalTransfrom={0}
        arrows
        autoPlay={true}
        autoPlaySpeed={7000}
        centerMode={false}
        className="NasTym--Carousel--Wrap"
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        showDots={true}
        dotListClass="custom-dot-list-style"
      >
        <S.Card>
          <S.ReferenceLogo
            src="/images/homepage/references/sladek_group.png"
            className="reference-logo"
            alt="Sládek Group"
          />
          <p>
            S EKP jsme spolupracovali v oblasti restrukturalizace naší
            společnosti, manažerském akciovém programu a přechod na holdingovou
            strukturu vlastnictví. Jsem rád za jejich systematický přístup a
            dlouhodobou podporu.
          </p>
          <S.NameAndPosition>Ing. Jan Sládek, Sládek Group</S.NameAndPosition>
        </S.Card>
        <S.Card>
          <S.ReferenceLogo
            src="/images/homepage/references/hubergroup.png"
            className="reference-logo"
            alt="Huber Group"
          />
          <p>
            S EKP spolupracujeme v oblasti účetnictví vedeném v programu SAP
            Business One, koncernovém reportingu systememu Tagetik a daňovém
            compliance. Oceňujeme zejména profesionální a individuální přístup s
            krátkými reakčními časy!
          </p>
          <S.NameAndPosition>Tomáš Bakule, hubergroup CZ</S.NameAndPosition>
        </S.Card>
        <S.Card>
          <S.ReferenceLogo
            src="/images/homepage/references/rotagroup.png"
            className="reference-logo"
            alt="Rota Group"
          />
          <p>
            S EKP spolupracujeme již několik let s důvěrou, že naše daňová
            rizika jsou nízká, účetnictví odpovídá realitě a jsme rádi, že znají
            i specifika developerské činnosti.
          </p>
          <S.NameAndPosition>Josef Brejcha, ROTAGROUP</S.NameAndPosition>
        </S.Card>
        <S.Card>
          <S.ReferenceLogo
            src="/images/homepage/references/afry.png"
            className="reference-logo"
            alt="AFRY"
          />
          <p>
            S EKP spolupracujeme pátým rokem v oblasti konzultací daně z příjmů
            a DPH. Jsme rádi, že našemu oboru rozumí a zvládají díky svým
            kontaktům v zahraničí i poradenství s mezinárodním přesahem.
          </p>
          <S.NameAndPosition>Jan Škaloud, AFRY</S.NameAndPosition>
        </S.Card>
        <S.Card>
          <S.ReferenceLogo
            src="/images/homepage/references/global_webindex.png"
            className="reference-logo"
            alt="Global Web Index"
          />
          <p>
            EKP assists us with the monthly accounting, reporting, VAT and
            payroll calculation of our Czech branch. We are very satisfied with
            their high level of professionalism, the quality of the work
            provided and their international accounting expertise.
          </p>
          <S.NameAndPosition>
            Isabella Scudeler, GlobalWebIndex
          </S.NameAndPosition>
        </S.Card>
        <S.Card>
          <S.ReferenceLogo
            src="/images/homepage/references/chemia_bomar.png"
            className="reference-logo"
            alt="Chemia Bomar"
          />
          <p>
            EKP represents us in a dispute with customs authority – customs
            dealing and legal action. We appreciate their approach of keeping us
            informed and active approach to the issue.
          </p>
          <S.NameAndPosition>Janusz Marciniak, CHEMIA BOMAR</S.NameAndPosition>
        </S.Card>
        <S.Card>
          <S.ReferenceLogo
            src="/images/homepage/references/witty_trade.png"
            className="reference-logo"
            alt="Witty Trade"
          />
          <p>
            S EKP spolupracujeme už od našeho vzniku a oceňuji, že nám dokázali
            pomoci s účetnictvím a daněmi - startupu o 5 lidech a dokáží to i
            nyní, když je nás 70 a máme obrat přes 2 mld. CZK.
          </p>
          <S.NameAndPosition>Viktor Hajíček, Witty Trade</S.NameAndPosition>
        </S.Card>
        <S.Card>
          <S.ReferenceLogo
            src="/images/homepage/references/xtuning.png"
            className="reference-logo"
            alt="Xtuning"
          />
          <p>
            S EKP spolupracujeme několik let a ceníme si zejména osobního
            přístupu a schopnosti poskytnout daňové a účetní poradenství při
            jednodušších i komplexních otázkách.
          </p>
          <S.NameAndPosition>Jakub Klobása, Xtuning</S.NameAndPosition>
        </S.Card>
        <S.Card>
          <S.ReferenceLogo
            src="/images/homepage/references/enteria.png"
            className="reference-logo"
            alt="Enteria"
          />
          <p>
            Na EKP se obracíme s ad hoc požadavky a vždy nám byly poskytnuty
            profesionální služby na vysoké úrovni, ať už se jednalo zajištění
            školení týmu účetních a ekonomů našeho koncernu, přípravu analýzy
            převodních cen nebo návrh strukturování nejrůznějších transakcí.
          </p>
          <S.NameAndPosition>Duspiva Pavel, Enteria</S.NameAndPosition>
        </S.Card>
        <S.Card>
          <S.ReferenceLogo
            src="/images/homepage/references/modry_angel.png"
            className="reference-logo"
            alt="Modrý Anděl"
          />
          <p>
            Běžné ekonomické procesy řešíme interně a na EKP se s důvěrou
            obracíme se speciálními požadavky. Vždy dokázali poradit a pomoci
            nalézt optimální řešení našich účetních a daňových problémů.
          </p>
          <S.NameAndPosition>Robert Faltýnek, MODRÝ ANDĚL</S.NameAndPosition>
        </S.Card>
        <S.Card>
          <S.ReferenceLogo
            src="/images/homepage/references/logo-tzb.png"
            alt="Schulte tzb"
          />
          <p>
            Tým EKP nás úspěšně provedl restrukturalizací skupiny a rádi se na
            ně obracíme s jakýmikoliv účetními a daňovými dotazy ať už jde o
            běžné provozní záležitosti nebo o podporu při strategickém
            rozhodování.
          </p>
          <S.NameAndPosition>Jan Bula, Schulte TZB.</S.NameAndPosition>
        </S.Card>
      </Carousel>
      <S.FooterPartner>
        <div className="col-lg-12">
          <div>
            <a
              href="https://partneri.shoptet.cz/profesionalove/ek-partners-s-r-o/"
              target="_blank"
            >
              <img src="/images/medal-shoptetrix-bronze.png" alt="Shoptet" />
            </a>
          </div>
        </div>
      </S.FooterPartner>
    </S.Wrapper>
  )
}

export default References
