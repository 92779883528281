import styled from 'styled-components'
import theme from '../../../common/theme'

export const Wrapper = styled.div`
  padding: 12rem 16px;
  text-align: center;
  font-family: 'Raleway';
  @media (max-width: 576px) {
    padding-bottom: 50px;
  }
`

export const NameAndPosition = styled.p`
  color: ${theme.color.blue};
  font-size: 2.2rem;
  margin-top: 43px;
  @media (max-width: 768px) {
  }
  @media (max-width: 576px) {
    font-size: 1.7rem;
    margin-top: 20px;
  }
`

export const Card = styled.div`
  padding: 54px 118px;
  text-align: center;
  background: ${theme.color.white};
  color: #6e6a6a;
  font-size: 2.1rem;
  border-radius: 10px;
  box-sizing: border-box;

  @media (max-width: 992px) {
    width: 100%;
    padding: 90px -5px 32px 16px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 32px 64px;
    font-size: 18px;
  }
`

export const CardsContainer = styled.div`
  margin-top: 320px;
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: middle;
`

export const H2Blue = styled.h2`
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 3.8rem;
  margin-bottom: 17px;
  color: ${theme.color.blue};
`

export const UnderlineYellow = styled.div`
  margin-top: 10px;
  width: 277px;
  margin-left: auto;
  margin-right: auto;
  height: 0px;
  border: 3px solid ${theme.color.yellow};
  border-radius: 20px;
  @media (max-width: 1414px) {
    width: 200px;
  }
  @media (max-width: 1200px) and (min-width: 576px) {
    width: 300px;
  }
`

export const ReferenceLogo = styled.img`
  max-width: 200px;
  max-height: 100px;
  padding-bottom: 16px;
`
export const FooterPartner = styled.div`
  margin: 50px auto 0;
  display: block;
  @media (max-width: 576px) {
    max-width: 100%;
  }
`
