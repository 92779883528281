import styled from 'styled-components'
import theme from '../../../common/theme'

export const Wrapper = styled.div`
  margin-top: 80px;
  padding-bottom: 10px;
`
/*
  margin-top: 197px;
  padding-bottom: 240px;
`*/

export const WrapCard = styled.div`
  padding-top: 20px;
  background: #ededed;
  color: ${theme.color.blue};
  text-align: center;
  margin-bottom: 40px;
  border-radius: 20px;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    background-color: ${theme.color.yellow};
  }
`

export const Card = styled.div`
  margin-top: 20px;
  background: ${theme.color.blue};
  padding: 46px 0px;
  border-radius: 0 0 17px 17px;
`
export const Title = styled.h3`
  font-size: 3.8rem;
  color: ${theme.color.blue};
  ${WrapCard}:hover & {
    color: ${theme.color.white};
  }
`
export const Subtitle = styled.div`
  font-size: 20px;
  padding: 0px 51px;
  font-weight: 600;
  color: ${theme.color.yellow};
  text-transform: uppercase;
  line-height: 31px;
`

export const paragraph = styled.p`
  color: white;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  padding-left: 16px;
  margin-top: 16px;

  @media (max-width: 1200px) {
    margin-top: 16px;
  }
`
export const UnderlineYellow = styled.div`
  margin-top: 10px;
  width: 277px;
  margin-left: auto;
  margin-right: auto;
  height: 0px;
  border: 3px solid ${theme.color.yellow};
  border-radius: 20px;
  @media (max-width: 1414px) {
    width: 200px;
  }
  @media (max-width: 1200px) and (min-width: 576px) {
    width: 300px;
  }

  ${WrapCard}:hover & {
    border: 3px solid ${theme.color.blue};
  }
`
export const WrapTextAndImage = styled.div`
  margin-top: 28px;
  padding: 0px 16px;
  margin-left: 32px;
  text-align: center;
  @media (max-width: 1200px) {
    display: block;
    margin-left: 0px;
  }
`

export const WrapImage = styled.div`
  margin: 0 auto;
`

export const ButtonWrap = styled.div`
  text-align: right;
  margin-top: 16px;
  margin-right: 32px;
  @media (max-width: 1200px) {
    text-align: center;
    margin-right: 0px;
    margin-top: 32px;
  }
`

export const Button = styled.span`
  background-color: ${theme.color.yellow};
  color: ${theme.color.blue};
  padding: 7px 70px;
  border: solid 2px #d5d110;
  border-radius: 11px;
  cursor: pointer;
  font-weight: 600;
  width: 200px;
  &:hover {
    transition: 0.3s;
    background-color: ${theme.color.blue};
    color: ${theme.color.white};
  }
`
