import styled from 'styled-components'
import theme from '../../../common/theme'

export const Wrapper = styled.div`
  text-align: center;
  background-color: ${theme.color.blue};
  border-radius: 23px;
  margin-top: 40px;
  padding: 65px 150px;
  @media (max-width: 768px) {
    padding: 65px 32px;
  }
  @media (max-width: 576px) {
    padding: 65px 16px;
  }
`
