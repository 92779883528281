import React from 'react'
import * as S from './styled'
import { ResponsiveImage } from '../../ResponsiveImage'
import { Link } from '@reach/router'
import Fade from 'react-reveal/Fade'
import GatsbyImage from 'gatsby-image'

const NewsAndBlog = ({ blog, news }) => {
  const blogUnwrap = blog.node
  const newsUnwrap = news.node
  return (
    <S.Wrapper>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Fade bottom>
              <S.WrapCard>
                <Link id="novinkyLink" to="/novinky-blog/novinky/">
                  <S.Title>Novinky</S.Title>
                  <S.UnderlineYellow />
                </Link>
                <Link to={newsUnwrap.fields.slug}>
                  <S.Card>
                    <S.Subtitle>{newsUnwrap.frontmatter.title}</S.Subtitle>
                    <S.WrapTextAndImage>
                      <S.WrapImage>
                        <GatsbyImage
                          alt="news"
                          fixed={
                            newsUnwrap.frontmatter.image.childImageSharp.fixed
                          }
                        />
                      </S.WrapImage>
                      <S.paragraph>{newsUnwrap.excerpt}</S.paragraph>
                    </S.WrapTextAndImage>
                    <S.ButtonWrap>
                      <S.Button>Více</S.Button>
                    </S.ButtonWrap>
                  </S.Card>
                </Link>
              </S.WrapCard>
            </Fade>
          </div>

          <div className="col-md-6">
            <Fade bottom delay={250}>
              <S.WrapCard>
                <Link id="blogLink" to="/novinky-blog/blog/">
                  <S.Title>Blog</S.Title>
                  <S.UnderlineYellow />
                </Link>
                <Link to={blogUnwrap.fields.slug}>
                  <S.Card>
                    <S.Subtitle>{blogUnwrap.frontmatter.title}</S.Subtitle>
                    <S.WrapTextAndImage>
                      <S.WrapImage>
                        <GatsbyImage
                          alt="blog"
                          fixed={
                            blogUnwrap.frontmatter.image.childImageSharp.fixed
                          }
                        />
                      </S.WrapImage>
                      <S.paragraph>{blogUnwrap.excerpt}</S.paragraph>
                    </S.WrapTextAndImage>

                    <S.ButtonWrap>
                      <S.Button>Více</S.Button>
                    </S.ButtonWrap>
                  </S.Card>
                </Link>
              </S.WrapCard>
            </Fade>
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}

export default NewsAndBlog
